@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.promo {
  border: 1px solid #d2d2d2;
  background: #FFFFFF;
  padding: 15px;
  overflow: hidden;
  /**
 * Grid Variables - CH
 */
  /**
 * Custom SASS Mixins - CH
 */
  /* Global RTE Typographic Styles for all office asset sites — CH */
  /* Custom WYSIWYG Style Classes - CH */
  /* Headings */
  /* Subheadings */
  /* Body Copy */
}

.promo .zg-height-fix .field-promoicon {
  margin-top: 100px;
}

.promo .field-promoicon {
  overflow: hidden;
  float: left;
  width: 100%;
}

.promo .promo-link {
  margin-top: 5px;
  padding-bottom: 10px;
}

.promo.image-full-size img {
  margin-bottom: 10px;
}

.promo h2, .promo h3 {
  margin-top: 14rem;
  margin-bottom: 7rem;
}

@media (max-width: 767px) {
  .promo h2, .promo h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
}

.promo h4 {
  margin-top: 10rem;
  margin-bottom: 7rem;
}

@media (max-width: 767px) {
  .promo h4 {
    margin-top: 7rem;
    margin-bottom: 5rem;
  }
}

.promo p {
  margin-bottom: 5rem;
}

.promo strong {
  font-weight: 700;
}

.promo ol, .promo ul {
  margin-bottom: 7rem;
  margin-left: 5rem;
}

.promo ol li + li, .promo ul li + li {
  margin-top: 4rem;
}

@media (max-width: 767px) {
  .promo ol, .promo ul {
    margin-left: 0;
  }
}

.promo ol li {
  margin-left: 2.5rem;
  padding-left: 1.2rem;
  list-style: decimal;
  list-style-position: outside;
}

.promo ul li {
  position: relative;
  margin: 0;
  padding-left: 3.7rem;
}

.promo ul li:before {
  display: block;
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: 1.7rem;
  height: 0.2rem;
  background-color: #977b59;
  content: '';
}

.promo a {
  color: #707070;
  text-decoration: underline;
}

.promo a:hover {
  color: #878787;
}

.promo table {
  height: auto !important;
  border: 2px solid #d2d2d2;
}

.promo table, .promo table tr, .promo table th, .promo table td {
  border: solid 2px #d2d2d2;
  background: #FFFFFF;
  border-collapse: collapse;
  vertical-align: middle;
}

.promo table tr, .promo table th, .promo table td {
  padding: 5px;
}

.promo table caption {
  margin-left: 0;
  padding: 10px;
  background-color: #f7f7f7;
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
}

.promo table tr {
  border: 0;
}

.promo table th, .promo table td {
  border-width: 2px 0 0 2px;
}

.promo table th {
  padding: 7px;
  background-color: white;
  font-size: 13px;
  font-weight: bold;
}

.promo table th:first-child {
  border-left: 0;
}

.promo table td:first-child {
  border-left: 0;
}

.promo .field-eventstart {
  padding-bottom: 1.9rem;
  font-size: 0.8em;
}

.promo blockquote {
  margin-top: 14rem;
  margin-bottom: 14rem;
  margin-left: 5rem;
  font-family: "Frank Ruhl Libre", serif;
  font-size: 1.6em;
  font-weight: 500;
}

@media (max-width: 767px) {
  .promo blockquote {
    margin-top: 10rem;
    margin-bottom: 10rem;
    margin-left: 3rem;
  }
}

.promo cite {
  font-family: "Roboto", sans-serif;
  font-size: 0.5em;
  font-weight: normal;
}

.promo cite:before {
  content: '"';
  font-family: "Frank Ruhl Libre", serif;
  line-height: 0.2em;
  font-size: 7.5em;
  color: #ee2737;
  display: block;
  margin-top: 0.75em;
}

.promo .mv-heading-1,
.promo .mv-heading-2,
.promo .mv-heading-3,
.promo .mv-heading-4,
.promo .mv-heading-5,
.promo .mv-heading-6,
.promo .mv-subheading-5,
.promo .mv-body-1,
.promo .mv-body-5 {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.promo .mv-heading-7,
.promo .mv-heading-8,
.promo .mv-subheading-1,
.promo .mv-subheading-2,
.promo .mv-subheading-3,
.promo .mv-subheading-4 {
  font-family: 'Frank Ruhl Libre', serif;
  font-weight: 500;
}

.promo .mv-heading-1 {
  font-size: 5.25em;
  line-height: 1em;
}

@media (max-width: 767px) {
  .promo .mv-heading-1 {
    font-size: 2.375em;
  }
}

.promo .mv-heading-2 {
  font-size: 4.5em;
  line-height: 1em;
}

.promo .mv-heading-3 {
  font-size: 3.25em;
  line-height: 1em;
}

.promo .mv-heading-4 {
  font-size: 3em;
  line-height: 1em;
}

.promo .mv-heading-5 {
  font-size: 2.375em;
  line-height: 1em;
}

.promo .mv-heading-6 {
  font-size: 2em;
  line-height: 1em;
}

.promo .mv-heading-7 {
  font-size: 4.5em;
  line-height: 1.06944em;
}

.promo .mv-heading-8 {
  font-size: 3.25em;
  line-height: 1.25em;
}

.promo .mv-subheading-1 {
  font-size: 2.375em;
  line-height: 1.5em;
}

@media (max-width: 767px) {
  .promo .mv-subheading-1 {
    font-size: 2em;
    line-height: 1.25em;
  }
}

.promo .mv-subheading-2 {
  display: block;
  padding: 1.7em 0;
  font-size: 2em;
  line-height: 1.25em;
}

@media (max-width: 767px) {
  .promo .mv-subheading-2 {
    font-size: 1.5em;
    line-height: 1em;
  }
}

.promo .mv-subheading-3 {
  display: block;
  margin-top: 2.22em;
  color: #000000;
  font-size: 1.5em;
  line-height: 1em;
}

.promo .mv-subheading-4 {
  font-size: 1.25em;
  line-height: 2em;
}

.promo .mv-subheading-5 {
  font-size: 1em;
  line-height: 1em;
}

.promo .mv-body-1 {
  font-size: 1.5em;
  line-height: 1em;
}

@media (max-width: 767px) {
  .promo .mv-body-1 {
    font-size: 1.5em;
    line-height: 1.5em;
  }
}

.promo .mv-body-2 {
  color: #77787c;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.5em;
}

@media (max-width: 767px) {
  .promo .mv-body-2 {
    font-size: 1.25em;
    line-height: 1.5em;
  }
}

.promo .mv-body-3 {
  font-size: 1.25em;
  line-height: 1.7em;
}

@media (max-width: 767px) {
  .promo .mv-body-3 {
    font-size: 1em;
    line-height: 1em;
  }
}

.promo .mv-body-3--bold {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.5em;
}

@media (max-width: 767px) {
  .promo .mv-body-3--bold {
    font-size: 1em;
    line-height: 1em;
  }
}

.promo .mv-body-4 {
  font-size: 1em;
  line-height: 1em;
}

.promo .mv-body-4--bold {
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
}

.promo .mv-body-5 {
  font-size: 1.25em;
  line-height: 1em;
}

.promo.absolute-bottom-link {
  position: relative;
}

.promo.absolute-bottom-link .promo-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.promo.promo-hero {
  position: relative;
  text-align: center;
}

.promo.promo-hero.promo-hero-half {
  float: left;
  width: 50%;
}

@media only screen and (max-width: 991px) {
  .promo.promo-hero.promo-hero-half {
    float: none;
    width: 100%;
  }
}

.promo.promo-hero .field-promotext {
  display: inline-block;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.promo.promo-hero .field-promotext > a {
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .promo.promo-hero .field-promotext {
    width: 100%;
    margin: 0;
  }
  .promo.promo-hero .field-promotext h1 {
    font-size: 24px;
    font-size: 2.4rem;
  }
  .promo.promo-hero .field-promotext h2, .promo.promo-hero .field-promotext h3, .promo.promo-hero .field-promotext h4 {
    font-size: 20px;
    font-size: 2rem;
  }
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.promo-shadow {
  max-width: 960px;
  padding: 0;
  border-top-width: 3px;
  border-top-color: #89C6CC;
  border-style: solid;
  overflow: visible;
  position: relative;
}

.promo-shadow.promo {
  float: left;
}

.promo-shadow > .component-content {
  padding: 15px;
  margin: 0 0 30px 0;
}

@media only screen and (max-width: 991px) {
  .promo-shadow > .component-content {
    margin: 0 10px 30px 10px;
  }
}

.promo-shadow > .component-content:before, .promo-shadow > .component-content:after {
  opacity: 0.7;
  box-shadow: 0 17px 10px rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: -1;
  height: 20%;
  max-height: 100px;
  max-width: 460px;
  width: 47%;
  content: "";
  bottom: 10px;
}

.promo-shadow > .component-content:before {
  left: 2%;
  transform: rotate(-3deg);
}

.promo-shadow > .component-content:after {
  right: 2%;
  transform: rotate(3deg);
}
